import Settings from "../domain/Settings";

export function init(): void {
	const settings = Settings.getInstance().nativeTemplates;

	if (!settings) {
		return;
	}

	const renderConfig = JSON.parse(JSON.stringify(settings.renderConfig));

	if (settings.darkModeType === 'nunl') {
		renderConfig.darkMode = document.querySelector('html')?.getAttribute('data-theme') === 'dark';
	}

	window.advert.nativeTemplates = Object.assign({}, window.advert.nativeTemplates, {renderConfig});
}
