import Settings from "../domain/Settings";
import {getSlotsAndPositions} from "../services/slotManager";
import cmd from "../utils/cmd";
import Slot from "../domain/Slot";
import sizeMappings from "../services/sizeMappings";
import {getCurrentBreakpoint} from "../services/breakpoints";

declare global {
	interface Window {
		ootag?: {
			queue?: Array<() => void>
			initializeOo?: (cfg: OptOutInitConfig) => void
			defineSlot?: (cfg: { adSlot: string, targetId: string }) => void
			undefineAllSlots?: () => void
			makeRequests?: (forceLoad?: boolean) => void
		};
	}
}

interface OptOutInitConfig {
	publisher: number;
	onlyNoConsent?: boolean;
	noRequestsOnPageLoad?: boolean;
	consentTimeOutMS?: number;
}

export interface OptOutConfig {
	publisher: number;
	slots: Record<Slot['name'], string>;
}

let slotMappings: Record<Slot['name'], string> = {},
	slotsDefined: Record<Slot['name'], boolean> = {};

export function init(): void {
	const settings = Settings.getInstance();

	if (!settings.features?.optOut)
		return;

	if (!settings.optOut?.publisher || !settings.optOut?.slots) {
		console.warn('[ADVERT] OptOut module is loaded but is missing configuration. Disabling.');
		return;
	}

	const currentBreakpoint = getCurrentBreakpoint();

	slotMappings = Object.fromEntries(Object.entries(settings.optOut.slots).filter(([slotName]) => {
		const slot = settings.slots.find(s => s.name === slotName),
			sizes = sizeMappings.getSizesFromSizeMapForBreakpoint(slot?.sizeMapping, currentBreakpoint);

		return !!sizes?.length;
	}));
	slotsDefined = Object.fromEntries(Object.keys(slotMappings).map(s => [s, false]));

	window.ootag = window.ootag || {};
	window.ootag.queue = window.ootag.queue || [];

	window.ootag.queue.push(() => {
		window.ootag.undefineAllSlots();
		window.ootag.initializeOo({
			publisher: settings.optOut.publisher,
			onlyNoConsent: true,
			noRequestsOnPageLoad: true,
			consentTimeOutMS: 500
		});
	});

	window.advert.loadSlot = loadOptOutTag;

	cmd.accept('loaded');
}

async function loadOptOutTag(platformName: string, nodeId?: string | HTMLElement): Promise<Array<Slot>> {
	const slots = await getSlotsAndPositions(platformName, nodeId);

	return slots.filter((slot) => {
		if (!slotMappings[slot.name] || slotsDefined[slot.name])
			return false;

		slotsDefined[slot.name] = true;
		slot.setDefined(true);
		slot.isEmpty = false;

		window.ootag.queue.push(() => {
			window.ootag.defineSlot({
				adSlot: slotMappings[slot.name],
				targetId: slot.domId
			});
		});

		if (Object.values(slotsDefined).every(b => b)) {
			window.ootag.queue.push(() => {
				window.ootag.makeRequests();
			});
		}

		return true;
	});
}
