let urlParameters = {};

_init(window.location.search);

function _init(str) {
	const params = str.substring(1).split('&');

	for (let i = 0; i < params.length; i++) {
		const pos = params[i].indexOf('=');

		if (pos > 0) {
			urlParameters[params[i].substring(0, pos)] = params[i].substring(pos + 1);
		} else {
			urlParameters[params[i]] = '';
		}
	}
}

function getParameter(key) {
	return urlParameters[key];
}

export default {
	'get': getParameter
};
