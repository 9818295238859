import querystring from './querystring';

if (typeof querystring.get('advertdebug') !== 'undefined' || window.localStorage.getItem('advertdebug') === 'true') {
	_load();
}

function _load() {
	try {
		const baseUrl = _getBaseUrl(),
			script = document.createElement('script');

		script.src = `${baseUrl}/advert-debug.js`;
		script.async = true;
		script.addEventListener('load', () => {
			const interval = setInterval(() => {
				if (window.advert.debug) {
					window.advert.debug.start();
					clearInterval(interval);
				}
			}, 1000);
		});

		document.head.appendChild(script);
	} catch (e) {
		console.warn('[ADVERT] Could not load debug script', e);
	}
}

function _getBaseUrl() {
	if (document.currentScript) {
		return document.currentScript.src.slice(0, document.currentScript.src.lastIndexOf('/'));
	}

	return 'https://advertising-cdn.dpgmedia.cloud/web-advertising/snapshot';
}
